import React from 'react';
import { func, shape, string } from 'prop-types';

import Image from '../../../../../../image';

const Picture = props => {
  const { id, dataTestId, href, className, arialabel, style, imageProps, onClick } = props;

  return (
    <a
      key={id}
      data-testid={dataTestId}
      href={href}
      onClick={onClick}
      className={className}
      style={style}
      aria-label={arialabel}
    >
      <Image alt={imageProps.alt} {...imageProps} />
    </a>
  );
};

Picture.propTypes = {
  id: string.isRequired,
  dataTestId: string.isRequired,
  href: string.isRequired,
  className: string.isRequired,
  arialabel: string.isRequired,
  style: shape({}).isRequired,
  imageProps: shape({}).isRequired,
  onClick: func.isRequired,
};

export default Picture;
